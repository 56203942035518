import React from 'react';
import { FaFacebookF, FaTwitter, FaGithub, FaGooglePlusG } from 'react-icons/fa';
import './footer.css';  // Make sure to create a CSS file for styling

const Footer = () => {
  return (
    <div className="footer">
      {/* <div className="social-icons">
        <a href="https://www.facebook.com" target="_blank" rel="noopener noreferrer" aria-label="Facebook">
          <FaFacebookF />
        </a>
        <a href="https://www.twitter.com" target="_blank" rel="noopener noreferrer" aria-label="Twitter">
          <FaTwitter />
        </a>
        <a href="https://www.github.com" target="_blank" rel="noopener noreferrer" aria-label="GitHub">
          <FaGithub />
        </a>
        <a href="https://plus.google.com" target="_blank" rel="noopener noreferrer" aria-label="Google Plus">
          <FaGooglePlusG />
        </a>
      </div> */}
      <p style={{ marginTop:"0px" }}>©️ 2024 by Taxpeon Limited. All rights reserved.</p>
    </div>
  );
};

export default Footer;
