// src/components/Status.js
import { useState, useRef, React } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMinus } from '@fortawesome/free-solid-svg-icons';
import './../components/status.css';
import { Input } from "../components/Input";
import { Button } from "../components/Button";
import * as AWS from 'aws-sdk';
import Alert from '@mui/material/Alert';

AWS.config.update({
    region: 'us-east-1',
    // endpoint: 'dynamodb.us-east-1.amazonaws.com',
    accessKeyId: 'AKIAVRUVWPCW75LA3DEC',
    secretAccessKey: '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'
});

const dbClient = new AWS.DynamoDB.DocumentClient();

const ses = new AWS.SES({
    region: 'us-east-1',
    smtp: {
        host: "email-smtp.us-east-1.amazonaws.com",
        port: 587,
        auth: {
            user: "AKIAVRUVWPCW6I2OJKCY",
            pass: "BGFNaJ494guVrTZHVlxV0MFdttUaWYkx8dq6EI8fHqAH"
        },
    },
})

export const Status = () => {
    const [email, setEmail] = useState();
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [emailExists, setEmailExists] = useState(false);
    const [error, setError] = useState(null);
    const emailRef = useRef();

    const submitForm = async (e) => {
        e.preventDefault();
        setError(null);
        
        const _email = emailRef.current.value;

          // Check if the email already exists in the table
          let checkParams = {
            TableName: "subscribe-users",
            Key: {
                email: _email
            }
        };
        
        // let params = {
        //     TableName: "subscribe-users",
        //     Item: {
        //         email: _email
        //     }
        // }

        try {

            const data = await dbClient.get(checkParams).promise();
            if (data.Item) {
                setEmailExists(true);
                setError('This email is already subscribed.');
                return;
            }

            let params = {
                TableName: "subscribe-users",
                Item: {
                    email: _email
                }
            }

            await dbClient.put(params).promise();
            setIsSubmitted(true);
            setEmailExists(false);


            const confirmationParams = {
                Source: '"Taxpeon says, Hello" <hello@taxpeon.com>',
                Destination: {
                    ToAddresses: [_email]
                },
                Message: {
                    Subject: {
                        Data: "Your Journey with Taxpeon, Begins Here!"
                    },
                    Body: {
                        Html: {
                            Data: `
                                <!DOCTYPE html>
                                <html lang="en">
                                <head>
                                <meta charset="UTF-8">
                                <meta name="viewport" content="width=device-width, initial-scale=1.0">
                                <meta http-equiv="X-UA-Compatible" content="IE=edge">
                                <title>Welcome to Taxpeon</title>
                                <style type="text/css">
                                    body { margin: 0; padding: 0; width: 100%; height: 100%; background-color: #f6f6f6; font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; color: #333333; }
                                    .wrapper { width: 100%; table-layout: fixed; background-color: #f6f6f6; padding: 20px 0; }
                                    .content { max-width: 600px; margin: 0 auto; background-color: #ffffff; border-radius: 10px; box-shadow: 0 4px 6px rgba(0,0,0,0.1); overflow: hidden; }
                                    .header { background-color: #0047bb; color: #ffffff; text-align: center; padding: 20px; font-size: 24px; font-weight: bold; }
                                    .header img { max-width: 100%; height: auto; }
                                    .body { padding: 20px; text-align: center; }
                                    .body h1 { font-size: 28px; color: #0047bb; }
                                    .body h6 { font-size: 16px; color: #387a53; margin-bottom: 20px; }
                                    .body p { font-size: 14px; color: #333333; line-height: 1.5; margin-bottom: 20px; }
                                    .unsubscribe { text-align: center; padding: 10px; margin: 20px 0; }
                                    .unsubscribe a { background-color: #9A031E; color: #ffffff; text-decoration: none; padding: 10px 20px; border-radius: 5px; font-size: 14px; display: inline-block; }
                                    .footer { background-color: #FAFDFF; text-align: center; padding: 10px; font-size: 12px; color: #777777; }
                                </style>
                                </head>
                                <body>
                                <div class="wrapper">
                                    <div class="content">
                                        <div class="header">
                                            <img src="https://taxpeon-asset.s3.amazonaws.com/white-logo.png" alt="Taxpeon Logo">
                                        </div>
                                        <div class="body">
                                            <h1>Make way for your ultimate companion in filing tax returns, effortlessly!</h1>
                                            <h6>Taxpeon is a fully-fledged tax filing solution for individuals, ensuring a stress-free experience. Soon, we will begin our operation and we welcome you to try our ground-breaking web application, for free, forever!</h6>
                                            <p><strong>Things that you may expect in the coming days,</strong></p>
                                            <p>1. You will be notified once we go live.</p>
                                            <p>2. You will be able to sign up using your email address or phone number to prepare your tax returns.</p>
                                            <p>3. As a member of our "First 1000 Customers", you will never have to pay us a dime to use our platform to prepare tax returns.*</p>
                                        </div>
                                        <div class="body">
                                            <h3>We have a guy seated on the other side. So, feel free to reply to this email, share your ideas, thoughts & feedback.</h3>
                                        </div>                         
                                        <div class="unsubscribe">
                                            <a href="http://taxpeon.com:8080/unsubscribe?email=${_email}" target="_blank">Unsubscribe Me from these Emails</a>
                                        </div>
                                        <div class="footer">
                                            *Terms and conditions apply. <br> © 2024 Taxpeon Limited.
                                        </div>
                                    </div>
                                </div>
                                </body>
                                </html>
                            `
                        }
                    }
                }
            };

            await ses.sendEmail(confirmationParams).promise();
        } catch (err) {
            console.error(err);
            setError('An error occurred. Please try again.');
        }

    
    };

    return (
        <div className="status-container">
            <div>
                <h3 className="subtitle">
                    We are building something groundbreaking!
                </h3>
                <h1 className="status">
                Launching soon - Your go-to solution for hassle-free submission of tax returns in Bangladesh. Subscribe now to be the first to experience seamless digital tax filing, with Taxpeon. 
                </h1>
                <br /> <br /> <br />

                {isSubmitted ? (
                    <Alert severity="success" color="info" style={{ fontFamily:"MyCustomFontSmall" }}>Thank you for subscribing! We will notify you once we launch.</Alert>
                ) : (
                <form className="mt-5" onSubmit={submitForm}>
                        <Input type='email' placeholder='Enter your email' inputRef={emailRef}/>
                        <Button buttonLabel="Get Notified"/>
                </form>
                )}

                {emailExists && (
                    <Alert severity="warning" color="warning" style={{ marginTop: '20px', fontFamily:"MyCustomFontSmall" }}>We already have your email on our list. We'll let you know when we go live!</Alert>
                )}
 
            </div>
        </div>
    );
};

export default Status;
