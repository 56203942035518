import React, { useState, useEffect, useCallback } from 'react';
import { differenceInSeconds, parseISO } from 'date-fns';
import "./countdown.css";

const Countdown = () => {
    const targetDate = parseISO('2024-11-26T00:00:00'); // 30th November 2024

    const calculateTimeLeft = useCallback(() => {
        const now = new Date();
        const totalSeconds = Math.max(differenceInSeconds(targetDate, now), 0);

        const days = Math.floor(totalSeconds / (24 * 60 * 60));
        const hours = Math.floor((totalSeconds % (24 * 60 * 60)) / (60 * 60));
        const minutes = Math.floor((totalSeconds % (60 * 60)) / 60);
        const seconds = totalSeconds % 60;

        return {
            days,
            hours,
            minutes,
            seconds,
        };
    }, [targetDate]);

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer); // Cleanup timer on component unmount
    }, [calculateTimeLeft]);

    return (
        <div className="countdown-container">
            <div className="countdown-item">
                {timeLeft.days}
                <div className="countdown-label">days</div>
            </div>
            <div className="countdown-item">
                {timeLeft.hours}
                <div className="countdown-label">hours</div>
            </div>
            <div className="countdown-item">
                {timeLeft.minutes}
                <div className="countdown-label">minutes</div>
            </div>
            <div className="countdown-item">
                {timeLeft.seconds}
                <div className="countdown-label">seconds</div>
            </div>
        </div>
    );
};

export default Countdown;
