import React from 'react';
import Background from './components/Background';
import Header from './components/Header';
import "./App.css"
import Countdown from './components/Countdown';
import Status from './components/Status';
import Footer from './components/Footer';

function App() {
  return (
    <div className="landing_page">
      <Header />
      <Background />
      <Countdown/>
      <Status/>
      <Footer/>
    </div>
  );
}

export default App;
