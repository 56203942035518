import React from "react";
import './Header.css';
import logo from './../img/logo.svg';

function Header() {
  return (
    <h1 className="logo">
      <img src={logo} alt="Logo" className="logo-image" />
    </h1>
  );
}

export default Header;
