import React from 'react';
import './Background.css';
import calculatorIcon from './../img/calculator.png'
import taxIcon from './../img/taxes.png';
import triangle from './../img/triangle.png';
import circle from './../img/circle.png';

const Background = () => {
  return (
    <div className="background">
      {/* Calculator icons */}
      <div className="calculator-icon icon calculator1" style={{ backgroundImage: `url(${calculatorIcon})` }}></div>
      <div className="calculator-icon icon calculator2" style={{ backgroundImage: `url(${calculatorIcon})` }}></div>
      <div className="calculator-icon icon calculator3" style={{ backgroundImage: `url(${calculatorIcon})` }}></div>
      
      

      {/* Tax icons */}
      <div className="tax-icon icon tax1" style={{ backgroundImage: `url(${taxIcon})` }}></div>
      <div className="tax-icon icon tax2" style={{ backgroundImage: `url(${taxIcon})` }}></div>
      <div className="tax-icon icon tax3" style={{ backgroundImage: `url(${taxIcon})` }}></div>

      {/* triangle icons */}
      <div className="triangle icon triangle1" style={{ backgroundImage: `url(${triangle})` }}></div>
      <div className="triangle icon triangle2" style={{ backgroundImage: `url(${triangle})` }}></div>
      <div className="triangle icon triangle3" style={{ backgroundImage: `url(${triangle})` }}></div>

      {/* Circular Icon */}
      <div className="circle icon circle1" style={{ backgroundImage: `url(${circle})` }}></div>
      <div className="circle icon circle2" style={{ backgroundImage: `url(${circle})` }}></div>
      <div className="circle icon circle3" style={{ backgroundImage: `url(${circle})` }}></div>
    </div>
  );
};

export default Background;
